import React from 'react';
import { ContactFormError } from './ContactFormError';
import { useDispatch, useSelector } from 'react-redux';
import { getModalData, getModalType } from '@state/modal/modalSelectors';
import { closeModal, ModalType } from '@state/modal/modalOperations';
import { BaseModal } from '../../../common/modal';
import { FormikValues } from 'formik';
import { useI18NextContext } from '@components/hooks';
import { Validation } from '@model/forms/validation/validation';

export interface ContactFormErrors {
  type: ContactForm;
  values: FormikValues;
}

export enum ContactForm {
  BOOKING_ENQUIRY = 'booking enquiry',
  GENERAL_ENQUIRY = 'general enquiry',
  REQUEST_QUOTE = 'quote request',
  WEBSITE_COMMENTS = 'website comment',
  RYANAIR_FLIGHTS_CANCELLATION = 'enquiry'
}

export enum ContactFormErrorFields {
  NAME = 'contact-form-modal__name--label',
  EMAIL = 'contact-form-modal__email--label',
  COMMENTS = 'contact-form-modal__comments--label',
  TELEPHONE = 'contact-form-modal__telephone--label',
  ENQUIRY = 'contact-form-modal__enquiry--label',
  BOOKING_REFERENCE = 'contact-form-modal__booking-reference--label',
  DATE_OF_TRAVEL = 'contact-form-modal__date-of-travel--label'
}

export enum RyanairFlightCancellationFormErrorFields {
  BOOKING_REFERENCE = 'form__field-label--booking-id'
}

export function getFormErrors(values: FormikValues, form: ContactForm, t: any): Array<string> {
  const errors: Array<string> = [];
  Object.entries(values).forEach(([key, value]) => {
    if (key === 'name') {
      if (!value) {
        errors.push(t('modal-error--field-missing-label', { field: t(ContactFormErrorFields.NAME) }));
      }
    }
    if (key === 'email') {
      if (!value) {
        errors.push(t('guests__modal--field-missing-label', { field: t(ContactFormErrorFields.EMAIL) }));
      } else if (!Validation.isEmailValid(value)) {
        errors.push(t('guests__modal--field-error-label', { field: t(ContactFormErrorFields.EMAIL) }));
      }
    }

    if (key === 'telephone') {
      if (!value) {
        errors.push(t('guests__modal--field-missing-label', { field: t(ContactFormErrorFields.TELEPHONE) }));
      } else {
        const validation = Validation.isTelephoneValidDetails(value);
        if (!validation.lengthValid) {
          errors.push(
            t('modal-error--field-error--telephone-length-label', { field: t(ContactFormErrorFields.TELEPHONE) })
          );
        } else if (!validation.formatValid) {
          errors.push(t('guests__modal--field-error-label', { field: t(ContactFormErrorFields.TELEPHONE) }));
        }
      }
    }

    if (key === 'comments') {
      if (!value) {
        errors.push(t('modal-error--field-missing-label', { field: t(ContactFormErrorFields.COMMENTS) }));
      }
    }

    if (key === 'enquiry') {
      if (!value) {
        errors.push(t('modal-error--field-missing-label', { field: t(ContactFormErrorFields.ENQUIRY) }));
      }
    }

    if (key === 'bookingReference') {
      if (form === ContactForm.RYANAIR_FLIGHTS_CANCELLATION) {
        if (!value) {
          errors.push(
            t('modal-error--field-missing-label', {
              field: t(RyanairFlightCancellationFormErrorFields.BOOKING_REFERENCE)
            })
          );
        } else if (!Validation.isBookingReferenceValid(values.bookingReference)) {
          errors.push(
            t('modal-error--field-error-label', {
              field: t(RyanairFlightCancellationFormErrorFields.BOOKING_REFERENCE)
            })
          );
        }
      } else {
        if (!value) {
          errors.push(t('modal-error--field-missing-label', { field: t(ContactFormErrorFields.BOOKING_REFERENCE) }));
        }
      }
    }

    if (form === ContactForm.BOOKING_ENQUIRY) {
      if (key === 'dateOfTravel') {
        if (!value.startDate || !value.endDate) {
          errors.push(t('modal-error--field-missing-label', { field: t(ContactFormErrorFields.DATE_OF_TRAVEL) }));
        }
      }
    }
  });
  return errors;
}

export const ContactFormErrorContainer = () => {
  const dispatch = useDispatch();
  const t: any = useI18NextContext();
  const modalType = useSelector(getModalType);
  const modalData: ContactFormErrors = useSelector(getModalData) as ContactFormErrors;
  const handleOnDismiss = () => dispatch(closeModal());
  return (
    <BaseModal open={modalType === ModalType.CONTACT_FORM_ERROR} onDismiss={handleOnDismiss} hideCloseButton>
      <ContactFormError
        type={modalData.type}
        errors={getFormErrors(modalData.values, modalData.type, t)}
        onClick={handleOnDismiss}
      />
    </BaseModal>
  );
};
