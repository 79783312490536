import { FormError } from '@components/modal/form-error/FormError';
import React from 'react';
import styled from '@emotion/styled';
import { useI18NextContext } from '@components/hooks';
import { APISFormGuestValues, APISFormValues } from '@model/forms/apis-form';
import { MyBookingGuest } from '@model/mmb';
import { Validation } from '@model/forms/validation/validation';
import { ThemeProps } from '@theme/base';

export interface APISGuestError {
  name: string;
  errors: Array<string>;
}

export interface APISError {
  guests: Array<APISGuestError>;
  ice: Array<string>;
  accuracyConfirmed: string;
}

export interface APISFormErrorProps {
  formValues: APISFormValues;
  guests: Array<MyBookingGuest>;
  onClick: () => void;
}

const ListItem: any = styled.li(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.paragraph as any)
}));

const SectionTitle: any = styled.p(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.paragraph as any),
  fontWeight: 'bold'
}));

export function getAPISErrors(formValues: APISFormValues, guests: Array<MyBookingGuest>, t: any): APISError {
  const guestErrors: Array<APISGuestError> = [];
  const iceErrors: Array<string> = [];
  let accuracyConfirmedError: string = '';

  formValues.guests.forEach((guestValues: APISFormGuestValues, index: number) => {
    const isLeadGuest: boolean = index === 0;
    const errorLabels: Array<string> = [];

    if (!guestValues.title) {
      errorLabels.push(t('modal-error--field-missing-label', { field: t('form__field-label--title') }));
    }

    if (!guestValues.firstName) {
      errorLabels.push(t('modal-error--field-missing-label', { field: t('form__field-label--first-name') }));
    }

    if (!guestValues.surname) {
      errorLabels.push(t('modal-error--field-missing-label', { field: t('form__field-label--surname') }));
    }

    if (!guestValues.gender) {
      errorLabels.push(t('modal-error--field-missing-label', { field: t('form__field-label--gender') }));
    }

    if (!guestValues.occupation) {
      errorLabels.push(t('modal-error--field-missing-label', { field: t('form__field-label--occupation') }));
    }

    if (!guestValues.firstName) {
      errorLabels.push(t('modal-error--field-missing-label', { field: t('form__field-label--first-name') }));
    }

    if (
      !Validation.isDateFormatValid(
        `${guestValues.dateOfBirth.year}-${guestValues.dateOfBirth.month}-${guestValues.dateOfBirth.day}`
      )
    ) {
      errorLabels.push(t('modal-error--field-error-label', { field: t('guests-form__field-label--dob') }));
    }

    if (!guestValues.placeOfBirth) {
      errorLabels.push(t('modal-error--field-missing-label', { field: t('form__field-label--place-of-birth') }));
    }

    if (!guestValues.nationality) {
      errorLabels.push(t('modal-error--field-missing-label', { field: t('form__field-label--nationality') }));
    }

    if (!guestValues.passportNumber) {
      errorLabels.push(t('modal-error--field-missing-label', { field: t('form__field-label--passport-number') }));
    }

    if (!guestValues.passportCountry) {
      errorLabels.push(t('modal-error--field-missing-label', { field: t('form__field-label--passport-country') }));
    }

    if (
      !Validation.isDateFormatValid(
        `${guestValues.passportIssueDate.year}-${guestValues.passportIssueDate.month}-${guestValues.passportIssueDate.day}`
      )
    ) {
      errorLabels.push(
        t('modal-error--field-error-label', { field: t('guests-form__field-label--passport-issue-date') })
      );
    }

    if (
      !Validation.isDateFormatValid(
        `${guestValues.passportExpiryDate.year}-${guestValues.passportExpiryDate.month}-${guestValues.passportExpiryDate.day}`
      )
    ) {
      errorLabels.push(
        t('modal-error--field-error-label', { field: t('guests-form__field-label--passport-expiry-date') })
      );
    }

    if (isLeadGuest) {
      if (!guestValues.telephone) {
        errorLabels.push(t('modal-error--field-missing-label', { field: t('form__field-label--telephone') }));
      } else {
        const validation = Validation.isTelephoneValidDetails(guestValues.telephone);
        if (!validation.lengthValid) {
          t('modal-error--field-error--telephone-length-label', { field: t('guests-form__field-label--telephone') });
        } else if (!validation.formatValid) {
          t('modal-error--field-error-label', { field: t('guests-form__field-label--telephone') });
        } else if (!validation.isValid) {
          t('modal-error--field-error-label', { field: t('guests-form__field-label--telephone') });
        }
      }

      if (!guestValues.email) {
        errorLabels.push(t('modal-error--field-missing-label', { field: t('form__field-label--email-address') }));
      } else if (!Validation.isEmailValid(guestValues.email)) {
        t('modal-error--field-error-label', { field: t('guests-form__field-label--email-address') });
      }
    }

    guestErrors.push({
      name: `${guests[index].firstNames} ${guests[index].lastName}`,
      errors: errorLabels
    });
  });

  if (!formValues.ice.firstName) {
    iceErrors.push(t('modal-error--field-missing-label', { field: t('form__field-label--first-name') }));
  }

  if (!formValues.ice.surname) {
    iceErrors.push(t('modal-error--field-missing-label', { field: t('form__field-label--surname') }));
  }

  if (!formValues.ice.telephone) {
    iceErrors.push(t('modal-error--field-missing-label', { field: t('form__field-label--contact-number') }));
  } else {
    const validation = Validation.isTelephoneValidDetails(formValues.ice.telephone);
    if (!validation.isValid) {
      t('modal-error--field-error-label', { field: t('guests-form__field-label--contact-number') });
    } else if (!validation.lengthValid) {
      t('modal-error--field-error--telephone-length-label', { field: t('guests-form__field-label--contact-number') });
    } else if (!validation.formatValid) {
      t('modal-error--field-error-label', { field: t('guests-form__field-label--contact-number') });
    }
  }

  if (!formValues.ice.email) {
    iceErrors.push(t('modal-error--field-missing-label', { field: t('form__field-label--email-address') }));
  } else if (!Validation.isEmailValid(formValues.ice.email)) {
    t('modal-error--field-error-label', { field: t('guests-form__field-label--email-address') });
  }

  if (!formValues.accuracyConfirmed) {
    accuracyConfirmedError = t('form__field-error--information-accuracy-confirmation');
  }

  return {
    guests: guestErrors,
    ice: iceErrors,
    accuracyConfirmed: accuracyConfirmedError
  };
}

export const APISFormError = ({ onClick, formValues, guests }: APISFormErrorProps) => {
  const t: any = useI18NextContext();
  const errors: APISError = getAPISErrors(formValues, guests, t);
  const renderErrors = () => (
    <>
      {errors.guests.map(
        (guestError: APISGuestError, index: number) =>
          !!guestError.errors.length && (
            <div key={guestError.name}>
              <SectionTitle>{guestError.name}</SectionTitle>
              <ul>
                {guestError.errors.map((message: string) => (
                  <ListItem key={`${message}-${index}`}>{message}</ListItem>
                ))}
              </ul>
            </div>
          )
      )}
      {!!errors.ice.length && (
        <>
          <SectionTitle>{t('mmb_apis-form--mergency-contact-details-title')}</SectionTitle>
          <ul>
            {errors.ice.map((error: string) => (
              <ListItem key={error}>{error}</ListItem>
            ))}
          </ul>
        </>
      )}
      {!!errors.accuracyConfirmed && <p>{errors.accuracyConfirmed}</p>}
    </>
  );
  return (
    <FormError
      onClick={onClick}
      title={'guests__modal--title-label'}
      body={'guests__modal--body-label'}
      renderErrors={renderErrors}
      buttonLabel={'guests__modal--button-label'}
    />
  );
};
